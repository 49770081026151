<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36" v-if="!profile.isStaff"/>
        <!-- <sidebar-link to="/dashboard/icons" :name="$t('sidebar.icons')" icon="tim-icons icon-atom"/>
        <sidebar-link to="/dashboard/maps" :name="$t('sidebar.maps')" icon="tim-icons icon-pin"/>
        <sidebar-link to="/dashboard/notifications" :name="$t('sidebar.notifications')" icon="tim-icons icon-bell-55"/> -->
        <sidebar-link to="/dashboard/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02" v-if="!profile.isStaff"/>
        <sidebar-link to="/dashboard/files" :name="$t('sidebar.files')" icon="tim-icons icon-puzzle-10" v-if="profile.isStaff"/>
        <sidebar-link to="/dashboard/investors" :name="$t('sidebar.investors')" icon="tim-icons icon-align-center" v-if="profile.isStaff"/>
        <div class="log-out-countainer" @click="logOut()"><b>Log out</b></div>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
.log-out-countainer {
  margin: 30px;
  color: #ffffff;
  text-align: center;
  background-color: #606060;
  font-family: "Open Saenz", sans-serif;
  font-size: 0.875rem;
  border-radius: 10px;
  cursor: pointer;
}
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import {mapState, mapActions} from 'vuex';
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  computed: {
    ...mapState(['profile']),
  },
  methods: {
    ...mapActions(['logOut']),
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>

import VueRouter from "vue-router";
import routes from "./routes";
import {auth} from "../firebase"

// configure router
const router = new VueRouter({
  hash: false,
  mode: 'history',
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.requiredAuth && !auth.currentUser) {
    next({
      path: "/login"
    })
  } else if (to.name === "login" && auth.currentUser) {
    next({
      path: "/dashboard"
    })
  } else {
    next()
  }
})

export default router;

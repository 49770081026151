import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
    apiKey: "AIzaSyAuJcasH45hAHUT6edJTXzmLqHQ8cAOJLo",
    authDomain: "biox-investor-web.firebaseapp.com",
    databaseURL: "https://biox-investor-web.firebaseio.com",
    projectId: "biox-investor-web",
    storageBucket: "biox-investor-web.appspot.com",
    messagingSenderId: "333793434545",
    appId: "1:333793434545:web:019b0fbb6c9843a7913b90",
    measurementId: "G-V3Z202414D"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.database()
const auth = firebase.auth()
const storage = firebase.storage()
const functions = firebase.functions()
export { db, auth, storage, functions }
import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Login = () => import(/* webpackChunkName: "common" */ "@/pages/Login.vue");
const Files = () => import(/* webpackChunkName: "common" */ "@/pages/Admin/Files.vue");
const Investors = () => import(/* webpackChunkName: "common" */ "@/pages/Admin/Investors.vue");
const PasswordRecovery = () => import(/* webpackChunkName: "common" */ "@/pages/PasswordRecovery.vue");
const ResetPassword = () => import(/* webpackChunkName: "common" */ "@/pages/ForgotPassword.vue");

const routes = [
  {
    path: "/",
    component: Login,
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
    name: "login"
  },
  {
    path: "/forgot-password",
    component: PasswordRecovery,
    name: "forgotPassword"
  },
  {
    path: "/resetPassword",
    component: ResetPassword,
    name: "resetPassword"
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: {requiredAuth: true},
    children: [
      {
        path: "",
        name: "dashboard",
        component: Dashboard,
        meta: {requiredAuth: true}
      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
        meta: {requiredAuth: true}
      },
      {
        path: "files",
        name: "files",
        component: Files,
        meta: {requiredAuth: true}
      },
      {
        path: "investors",
        name: "investors",
        component: Investors,
        meta: {requiredAuth: true}
      },
      // {
      //   path: "notifications",
      //   name: "notifications",
      //   component: Notifications
      // },
      // {
      //   path: "icons",
      //   name: "icons",
      //   component: Icons
      // },
      // {
      //   path: "maps",
      //   name: "maps",
      //   component: Maps
      // },
      // {
      //   path: "typography",
      //   name: "typography",
      //   component: Typography
      // },
      // {
      //   path: "table-list",
      //   name: "table-list",
      //   component: TableList
      // }
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;

import { db, auth, storage, functions } from '../firebase'
import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
Vue.use(Vuex)



// Format Number:
function numberFormat(amount, decimals) {
  amount += '';
  amount = parseFloat(amount.replace(/[^0-9\.]/g, ''));
  decimals = decimals || 0;

  if (isNaN(amount) || amount === 0)
      return parseFloat(0).toFixed(decimals);

  amount = '' + amount.toFixed(decimals);

  var amount_parts = amount.split('.'),
      regexp = /(\d+)(\d{3})/;

  while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, '$1' + ',' + '$2');

  return amount_parts.join('.');
}

function writeFileDataBase(document, url, path) {
  db.ref(path).set({
    date: getFormattedDate(),
    name: document.name,
    isDownloadeable: document.download,
    url: url
  })
}

function getFormattedDate() {
  const date = new Date()
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return ' ' + month + '-' + day + '-' + year;
}

function signIn(email, password, commit) {
  commit('setError', "")
  commit('awaitServiceResponse', true)
  auth.signInWithEmailAndPassword(email, password)
  .then (res => {
    commit('awaitServiceResponse', false)
    commit('setUser', res)
    const uid = auth.currentUser.uid
    getProfile(commit, uid)
  })
  .catch(error => {
    commit('awaitServiceResponse', false)
    commit('setError', error)
  })
}

function getProfile(commit, uid) {
  const reference = db.ref('/users/' + uid).once('value')
  .then (res => {
    const user = res.val()
    const profile = {
      name: user && user.name,
      taxID: user && user.taxID,
      email: user && user.email,
      numberOfShares: user && numberFormat(user.numberOfShares,0),
      percentOfTotalShares: user && numberFormat(user.percentOfTotalShares * 100, 5),
      phoneNumber: user && user.phoneNumber,
      address: user && user.address,
      zipCode: user && user.zipCode,
      locality: user && user.locality,
      country: user && user.country,
      state: user && user.state,
      pvv: user && numberFormat(user.pvv, 2),
      isStaff: user && user.isStaff
    }
    commit('setProfile', profile)
    const path = profile.isStaff ? "/dashboard/files" : "/dashboard"
    router.push(path)
  })
  .catch(error => {
    commit('setError', error)
  })
}


export default new Vuex.Store({
  state: {
    stockValues: null,
    user: null,
    profile: null,
    error: null,
    users: [],
    loader: false,
    publicDocuments: [],
    privateDocuments: [],
    recoveryError: null
  },
  mutations: {
    setUser(state, payload){
        state.user = payload
    },
    setError(state, payload){
      state.error = payload
    },
    setProfile(state,payload){
      state.profile = payload
    },
    setUsers(state, payload) {
      state.users = payload
    },
    setStockValues(state, payload){
      state.stockValues = payload
    },
    setPublicDocuments(state, payload){
      state.publicDocuments = payload
    },
    setPrivateDocuments(state, payload){
      state.privateDocuments = payload
    },
    awaitServiceResponse(state, payload){
      state.loader = payload
    },
    recoveryResponse(state, payload){
      state.recoveryError = payload
    }

  },
  actions: {
    createUser({commit}, user){
      auth.createUserWithEmailAndPassword(user.email, user.password)
      .then(res => {
        const user = {
          email: res.user.email,
          uid: res.user.uid
        }
        commit('setUser', user)
        commit('setError', '')
      })
      .catch(error => {
        commit('setError', error)
      })
    },
    signIn({commit}, user){
      signIn(user.email, user.password,commit)
    },
    logOut({commit}){
      auth.signOut()
      .then (res => {
        commit('setUser', null)
        router.push("/login")
      })
      .catch(error => {
        commit('setError', error)
      })
    },
    // Detect Active/Inactive User:
    detectUser({commit}, user){
      commit('setUser', user)
    },
    addFile({commit}, document){
      commit('awaitServiceResponse', true)
      const storagePath = !document.isPrivate ? "publicDocuments" : 'privateDocuments/' + document.uid
      const databasePath = !document.isPrivate ? "publicDocuments/" : "users/" + document.uid + "/documents/"
      const refImage = storage.ref().child(storagePath).child(document.name + '.' + document.extension)
      const res = refImage.put(document.file).then(() => {
        commit('awaitServiceResponse', false)
        const url = refImage.getDownloadURL().then((url) => {
          commit('awaitServiceResponse', false)
          writeFileDataBase(document, url, databasePath + document.name);
        });
      })
    },
    addUserFile({commit}, document){
      const storagePath = "excel/"
      const refImage = storage.ref().child(storagePath).child(document.name)
      const res = refImage.put(document.file).then(() => {
        const url = refImage.getDownloadURL().then((url) => {});
      })
    },
    createUserFile({commit}, document){
      commit('awaitServiceResponse', true)
      const storagePath = "excel/"
      const refImage = storage.ref().child(storagePath).child(document.name+'.xlsx')
      const res = refImage.put(document.file).then(() => {
        commit('awaitServiceResponse', false)
        const url = refImage.getDownloadURL().then((url) => {});
      })
    },
    deleteUserFile({commit}, document){
      commit('awaitServiceResponse', true)
      const storagePath = "excel/"
      const refImage = storage.ref().child(storagePath).child(document.name+'.xlsx')
      const res = refImage.put(document.file).then(() => {
        commit('awaitServiceResponse', false)
        const url = refImage.getDownloadURL().then((url) => {});
      })
    },
    updateUserFile({commit}, document){
      commit('awaitServiceResponse', true)
      const storagePath = "excel/"
      const refImage = storage.ref().child(storagePath).child(document.name+'.xlsx')
      const res = refImage.put(document.file).then(() => {
        commit('awaitServiceResponse', false)
        const url = refImage.getDownloadURL().then((url) => {});
      })
    },
    getUsers({commit}) {
      const reference = db.ref('/users/').once('value')
      .then ( res => {
        let users = []
        const userReference = res.val()
        Object.keys(userReference).forEach(function (key) {
          const user = {
            uid: key,
            name: userReference[key] && userReference[key].name
          }
          users.push(user)
          commit('setUsers', users)
        })
      })
      .catch(error => {
        commit('setError', error)
      })
    },
    saveUser({commit}, update) {
      const userId = auth.currentUser.uid
      const profile = {
        ...this.state.profile,
        email: update.email,
        phoneNumber: update.phoneNumber,
        address: update.address,
        zipCode: update.zipCode,
        locality: update.locality,
        country: update.locality,
        state: update.state,
      }
      db.ref('users/' + userId).update({
        email: update.email,
        phoneNumber: update.phoneNumber,
        address: update.address,
        zipCode: update.zipCode,
        locality: update.locality,
        country: update.country,
        state: update.state,
      })
      .then (res => {
        commit('setProfile', profile)
      })
    },
    recoverPassword({commit}, body ) {
      commit('awaitServiceResponse', true)
      fetch('https://us-central1-biox-investor-web.cloudfunctions.net/resetPassword', {
        method: 'POST',
        body:    JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
    })
    .then(res => {
      commit('awaitServiceResponse', false)
      if (res.ok){
        router.push("/login")
      }else{
        console.log(res)
        commit('recoveryResponse', "Invalid Key or recovery email expired")
        router.push("/resetPassword")
      }
    })
      // .then(json => console.log(json));
        // var resetPassword = functions.httpsCallable('resetPassword');
        // resetPassword({
        //     actionCode:body.actionCode,
        //     newPassword:body.newPassword
        //               })
        // .then(function(result) {
        //   router.push("/login")
        // }).catch(function(error) {
        //   //manage error
        // })
    },
    getStockValues({commit}, callback){
      commit('awaitServiceResponse', true)
      var unirest = require("unirest");
      var req = unirest("GET", "https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v3/get-historical-data");

      req.query({
        "region": "US",
        "symbol": "BIOX"
      });

      req.headers({
        "x-rapidapi-host": "apidojo-yahoo-finance-v1.p.rapidapi.com",
        "x-rapidapi-key": "75b3c35aafmshbcdb735c64e39f4p19abfajsncd6a1c50613a",
        "useQueryString": true
      });

      req.end(function (res) {
        if (res.error) throw new Error(res.error);
        var stockData = {
          date : [],
          stock : [
            [],
            [],
            []
          ]

        }
        for (let i=0; i<=15; i++) {
            var timestamp = res.body.prices[i].date
            var date = new Date(timestamp*1000);
            var _mes=date.getMonth()+1; //getMonth devuelve el mes empezando por 0
            var _dia=date.getDate(); //getDate devuelve el dia del mes
            var _anyo=date.getFullYear();

            stockData.date.unshift(_dia+"-"+_mes+"-"+_anyo)
            stockData.stock[1].unshift( res.body.prices[i].volume)
            stockData.stock[0].unshift(res.body.prices[i].adjclose)
            stockData.stock[2].unshift(res.body.prices[i].high)
        };
        commit('awaitServiceResponse', false)
        commit('setStockValues', stockData )
        callback()
      });
    },
    getPublicDocuments({commit}){

     db.ref('/publicDocuments').once('value')
      .then ( res => {
        const documents = []
        const docReference = res.val()
        Object.keys(docReference).forEach(function (key) {
          const doc = {
            name: key,
            // date: docReference[key] && docReference[key].date,
            action: docReference[key].isDownloadeable ? "Read/Download" : "Read Only",
            url: docReference[key] && docReference[key].url,
            // type: 'Read/Download',
            isDownloadeable: docReference[key] && docReference[key].isDownloadeable
          }
          documents.push(doc)
        })

        commit('setPublicDocuments', documents )
      })
    },
    getPrivateDocuments({commit}){
      const uid = auth.currentUser.uid

      db.ref('/users/' + uid + '/documents').once('value')
       .then ( res => {
         const documents = []
         const docReference = res.val()
         Object.keys(docReference).forEach(function (key) {

           const doc = {
             name: key,
            //  date: docReference[key] && docReference[key].date,
             action: docReference[key].isDownloadeable ? "Read/Download" : "Read Only",
             url: docReference[key] && docReference[key].url,
            //  type: 'Read Only',
             isDownloadeable: docReference[key] && docReference[key].isDownloadeable
           }
           console.log('tag', doc)
           documents.push(doc)
         })
         commit('setPrivateDocuments', documents )
       })
     },
  },
  getters: {
    userExist(state){
      if (state.usuario === null){
        return false
      }else{
        return true
      }
    }
  },
  modules: {

  }
})
